<template>
  <div class="new-applications">
    <div class="new-applications__wrapper">

      <!--   FILTERS   -->
      <b-row>
        <b-col cols="12">
          <filters
              :disabled="loading"
              @input="selectFilter"
              @select:filter="selectFilter"
              @apply="apply"
          />
        </b-col>
      </b-row>
      <!--      -->

      <!--  ACTION TABLE   -->
      <b-row>
        <b-col cols="12">
          <main-table
            :list="actionsData"
            :fields="byAppActionsFields"
            :loading="loading"
            sort-value=""
            title="By status in Applications task list"
            @click:update="updateActionsData"
          />
        </b-col>
      </b-row>
      <!--      -->

      <!--  EMPLOYEE TABLE   -->
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header>
              <div class="w-100 d-flex justify-content-between align-items-center">
                <b-card-title>Customer managers</b-card-title>

                <div class="d-flex align-items-center">
                  <b-button
                      variant="outline-secondary"
                      style="width: 40px"
                      class="px-0"
                      :disabled="loading"
                      @click="updateEmployeesData"
                  >
                    <feather-icon size="16" icon="RefreshCwIcon"/>
                  </b-button>
                </div>
              </div>
            </b-card-header>

            <div class="fill-row-loading">
              <employeeTable :list="appData" :fields="byEmployeeFields" :loading="loading" />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <!--      -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ApplicationService } from '@/services/new-applications.service'
import Filters from '@/components/filters/Filters.vue'
import mainTable from '@/components/tables/MainTable.vue'
import employeeTable from '@/components/applications/Table.vue'

import {
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BCardText,
  BSpinner
} from 'bootstrap-vue';

import applicationEmployeesModel, { applicationsActionsModel } from '@/models/customerSupport-model';

export default {
  name: 'NewApplications',

  metaInfo: {
    title: 'New Applications Report',
  },

  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BCardText,
    BSpinner,
    Filters,
    mainTable,
    employeeTable,
  },

  setup(props, ctx) {
    const svc = new ApplicationService(ctx.root.$http);

    return {
      svc,
      byAppActionsFields: [
        { key: 'name', label: 'State', sortable: false, tooltip: 'State that is selected while Notes are added' },
        { key: 'amount', label: 'Amount', sortable: true, tooltip: 'Current number of  tasks with corresponding status' },
        { key: 'percents', label: 'Percentage', sortable: true, tooltip: 'Relative percentage to total number of tasks' }
      ],

      byEmployeeFields: [
        {
          key: 'name',
          label: 'Email',
          sortable: true,
          tooltip: 'Email of customer service manager'
        },
        {
          key: 'all_actions',
          label: 'Task management actions',
          sortable: true,
          tooltip: 'Number of Notes added in Applications task list (for the selected period)'
        },
        { key: 'no_pick_up', label: 'Didn\'t pick up', sortable: true },
        { key: 'call_back', label: 'Call back', sortable: true },
        { key: 'other', label: 'Other', sortable: true },
        { key: 'canceled', label: 'Cancelled - after contact', sortable: true },
        { key: 'canceled_no_con', label: 'Cancelled - no contact', sortable: true },
        { key: 'will_pay', label: 'Will pay', sortable: true },
        { key: 'paid', label: 'Paid', sortable: true , tooltip: 'Number of students that paid after ‘Will pay’ status added on Applications task list'},
        { key: 'paid_rate', label: 'Paid / Will Pay', sortable: true },
        { key: 'paid_after_other', label: 'PAID AFTER OTHER STATUSES', sortable: true },
      ]
    }
  },

  data: () => ({
    loading: false,
    appData: null,
    actionsData: null,
    managerEmail: '',
    filters: {
      date_after: null,
      date_before: null
    }
  }),

  computed: {
    ...mapGetters({
      user: 'user/user'
    }),

    hasDateAfter() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.date_after);
    },

    hasDateBefore() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.date_before);
    },
  },

  created() {
    // this.preSelectDateFilters()
  },

  methods: {
    async preSelectDateFilters() {
      let _today = this.$moment().format('YYYY-MM-DD')
      let _getCookies = this.$cookies.get('filters')

      if (!this.hasDateAfter || !this.hasDateBefore) await this.selectFilter({date_after: _today, date_before: _today})
      else await this.selectFilter({date_after: _getCookies.date_after, date_before: _getCookies.date_before})
      await this.apply();
    },

    async updateActionsData() {
      await this.loadActionsData()
    },

    async updateEmployeesData() {
      await this.loadEmployeesData()
    },

    loadData() {
      this.loadActionsData()
      this.loadEmployeesData()
    },

    async loadActionsData() {
      try {
        this.loading = true
        let res = await this.svc.getTaskListApplications()
        this.actionsData = res.map(item => new applicationsActionsModel(item))
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async loadEmployeesData(query) {
      try {
        this.loading = true
        let res = await this.svc.getEmployeesApplications({...this.$route.query, ...query})
        this.appData = res.map(item => new applicationEmployeesModel(item))
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async selectFilter(payload) {
      if (this.loading) return
      this.filters = {...this.filters, ...payload}
      this.$cookies.set('filters', this.filters);
      await this.setQuery(this.filters)
    },

    async apply() {
      await this.loadData()
    },

    async setQuery(data) {
      const query = { ...this.$route.query, ...data };
      Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null) && delete query[key]);
      await this.$router.push({ query }).catch(() => {});
    },
  },

  provide() {
    return {
      setQuery: this.setQuery
    }
  }
}
</script>