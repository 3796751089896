import {ApiServiceAbstract} from "@/services/api.service.abstract"

const qs = require('qs')

export class ApplicationService extends ApiServiceAbstract {
  constructor(http) {
    super(http);
  }

  getTaskListApplications = (query) => {
    return this.request(this.http.get, 'statistic/task_list/new_applications/', {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });
  }

  getEmployeesApplications = (query) => {
    return this.request(this.http.get, 'statistic/employee/new_applications/', {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });
  }
}
